import React from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { createCommercial } from "./services/commercial.service";
import { validatePhoneNumber } from "../../helpers/numbers.helper";

function CommercialAddModal({
  showAdd,
  handleClose,
  handleToast,
  addCommercialToList,
  showLoader,
}) {
  const { register, handleSubmit } = useForm();

  const commercialCreation = async (data) => {
    showLoader(true);
    data.tva = parseInt(data.tva);
    if (validatePhoneNumber(data.phone)) {
      const response = await createCommercial(data);
      let state = false;
      let message = "";
      let type = "";
      if (checkStatusCodes(response.data.statusCode)) {
        showLoader(false);

        state = true;
        message = response.data.message;
        type = "success";
        handleToast(state, type, message);
        addCommercialToList(response.data.data);
      } else {
        console.log(response);
        showLoader(false);
        state = true;
        message = response.data.message;
        type = "error";
        handleToast(state, type, message[0]);
      }
    } else {
      showLoader(false);
      handleToast(true, "error", "Le numéro de téléphone est incorrect");
    }
  };

  return (
    <Modal
      show={showAdd}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Ajouter un commercial
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={handleSubmit((data) => commercialCreation(data))}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="fullname">
                          Nom:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="mdi:company"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="fullname"
                            className="form-control sb-input"
                            type="text"
                            aria-label="fullname"
                            placeholder="Nom"
                            aria-describedby="basic-addon1"
                            {...register("name", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone" className="form-label">
                          Telephone
                        </label>
                        <div className="input-group ">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="ic:baseline-phone"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="phone"
                            className="form-control sb-input"
                            type="tel"
                            aria-label="Telephone"
                            placeholder="Ex: +22177.....87"
                            aria-describedby="basic-addon1"
                            {...register("phone", { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="material-symbols:mail"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="email"
                            className="form-control sb-input"
                            type="email"
                            aria-label="email"
                            placeholder="Saisissez votre adresse email"
                            aria-describedby="basic-addon1"
                            {...register("email", { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CommercialAddModal;

import React, { useEffect, useState } from "react";
import Loader from "../../components/partials/Loader";
import { Icon } from "@iconify/react";
import ToastMessage from "../../components/messages/ToastMessage";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import Pagination from "../../components/partials/Pagination";
import { invoiceList } from "../invoice/services/invoice.service";
import { commandeList } from "../commande/services/commande.service";
import InvoiceAddModal from "../invoice/components/InvoiceAddModal";
import { Link } from "react-router-dom";
import InvoicePayment from "../invoice/components/InvoicePayment";
import {
  dateFormatForSubmit,
  dateFormatToFr,
} from "../../helpers/dates.helper";
import CompanyInvoice from "../invoice/components/CompanyInvoice";
import { entrepriseList } from "../../components/customer/services/entreprise.service";

function Facture(props) {
  const [factures, setfactures] = useState([]);
  const [commandes, setCommandes] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [entreprises, setEntrprises] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showInvoiceCompany, setshowInvoiceCompany] = useState(false);

  const [match, setMach] = useState({
    date: "",
    match: "",
  });

  const [showStatus, setShowStatus] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });

  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowAdd(false);
    setshowInvoiceCompany(false);
    setShowStatus(false);
  };
  const handleShowAdd = () => setShowAdd(true);
  const handleshowInvoiceCompany = () => setshowInvoiceCompany(true);
  const handleShowStatus = () => setShowStatus(true);

  const fetchInvoiceList = async () => {
    const response = await invoiceList();
    if (checkStatusCodes(response.data.statusCode)) {
      setfactures(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  const fetchCommandeList = async () => {
    try {
      const response = await commandeList("/invoices/orders/without_invoice");
      if (checkStatusCodes(response.data.statusCode)) {
        console.log("Données reçues :", response.data.data);
        setCommandes(response.data.data.list);
        setLoaderState(false);
      } else {
        handleToast(
          true,
          "error",
          "Vous n'avez pas les authorisations réquises"
        );
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des commandes :", error);
    }
  };
  const fetchEntrepriseList = async () => {
    const response = await entrepriseList(
      `/customers/companies/all?page=1&limit=100`
    );
    if (checkStatusCodes(response.data.statusCode)) {
      setEntrprises(response.data.data.list);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };
  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await invoiceList(`/invoices/all?page=${page}&limit=10`);
      setfactures(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } catch (error) {}
  };

  const doSearch = async (e) => {
    e.preventDefault();
    setLoaderState(true);
    setCurrentPage(1);
    const date = match.date ? dateFormatForSubmit(match.date) : "";
    console.log(date);
    try {
      const response = await invoiceList(
        `/invoices/search?match=${match.match}&date=${date}`
      );
      setfactures(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } catch (error) {}
  };

  const handleStatusClick = (commande) => {
    setSelectedInvoice(commande);
    handleShowStatus();
  };
  // const type = usersType
  //   const handleEditClick = (Produit) => {
  //     // Mettez à jour l'état avec les données de l'utilisateur sélectionné
  //     // setfacturesTypes(type)
  //     setSelectedInvoice(Produit);
  //     // Affichez le modal pour l'édition ici
  //     handleshowInvoiceCompany();
  //   };

  const renderTableData = () => {
    return (
      <tbody className="list_admin">
        {factures && factures.length > 0 ? (
          factures.map((facture) => (
            <tr key={facture?.code}>
              <td>{facture?.number}</td>
              <td>{dateFormatToFr(facture?.date).split(" ")[0]}</td>
              <td>{facture?.amount} XOF</td>
              <td>{facture?.advance} XOF</td>
              <td>{facture?.remaining} XOF</td>
              <td>{facture?.order_number}</td>
              <td>{facture?.owner?.name}</td>
              <td>
                {facture.status === "deposit" ? (
                  <button
                    className="btn btn-outline-secondary btn-sm text-truncate"
                    type="button"
                    onClick={() => handleStatusClick(facture)}
                  >
                    Avance <i className="fas fa-clock"></i>
                  </button>
                ) : facture?.status === "paid" ? (
                  <button
                    className="btn btn-outline-success btn-sm text-truncate disabled"
                    type="button"
                    onClick={() => handleStatusClick(facture)}
                  >
                    Payé <i className="fas fa-check"></i>
                  </button>
                ) : facture?.status === "not_paid" ? (
                  <button
                    className="btn btn-outline-danger btn-sm text-truncate"
                    type="button"
                    onClick={() => handleStatusClick(facture)}
                  >
                    Non payé <i className="fas fa-times"></i>
                  </button>
                ) : null}
              </td>

              <td>
                {/* <div className="d-flex align-items-center justify-content-center">
                  <Link
                    className="btn ms-2 btn-primary px-2 py-1"
                    title="voir facture"
                    to={`/me/facture/${facture?.code}`}
                  >
                    <i className="text-500 text-white fas fa-eye"></i>
                  </Link>
                  <button
                    className="btn ms-2 btn-warning px-2 py-1"
                    type="button"
                    title="Modifier"
                    onClick={() => handleEditClick(facture)}
                  >
                    <i className="text-500 text-white fas fa-edit"></i>
                  </button>
                </div> */}
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal dropdown-caret-none"
                    id="email-options"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-boundary="viewport"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span
                      className="fas fa-ellipsis-v"
                      data-fa-transform="down-2"
                    ></span>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-end border py-2"
                    aria-labelledby="email-options"
                  >
                    <Link
                      className="dropdown-item"
                      to={`/me/facture/${facture?.code}`}
                    >
                      <Icon
                        className="me-2"
                        icon="mdi:eye"
                        width="20"
                        height="20"
                      />
                      Voir plus
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item"
                      to={`/me/facture/${facture?.code}`}
                    >
                      <Icon
                        className="me-2"
                        icon="fa-solid:file-invoice"
                        width="15"
                        height="20"
                      />
                      <span>voir facture</span>
                    </Link>

                    <div className="dropdown-divider"></div>
                    <Link
                      className="dropdown-item"
                      to={`/me/borderau/${facture?.code}`}
                    >
                      <Icon
                        className="me-2"
                        icon="basil:invoice-solid"
                        width="20"
                        height="20"
                      />
                      Borderau de livraison
                    </Link>
                  </div>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9" className="text-center">
              Aucune facture disponible.
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  useEffect(() => {
    setLoaderState(true);
    fetchCommandeList();
    fetchInvoiceList();
    fetchEntrepriseList();
    console.log(commandes);
  }, []);

  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="basil:invoice-solid" width="50" height="50" />{" "}
                    Factures
                  </h3>
                  <p className="mb-0">Gestion des factures</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">Enrégistrer une facture</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row p-0">
                <div className="col-md-4">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="text"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                      onChange={(data) => {
                        setMach({ ...match, match: data.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="date"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                      onChange={(data) => {
                        setMach({ ...match, date: data.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="col-12 input-group" id="search_box">
                        <span className="input-group-text" id="basic-addon3">
                          <a
                            href="/#"
                            className="search"
                            onClick={(e) => doSearch(e)}
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div
                        className="d-flex align-items-center justify-content-end"
                        id="bulk-select-replace-element"
                      >
                        <button
                          className="btn btn-falcon-success btn-sm"
                          type="button"
                          onClick={handleshowInvoiceCompany}
                        >
                          <span
                            className="fas fa-plus"
                            data-fa-transform="shrink-3 down-2"
                          ></span>
                          <span className="ms-1">facture entreprise</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Les factures
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort">N°</th>
                            <th className="sort">Date</th>
                            <th className="sort">Somme</th>
                            <th className="sort">Avance</th>
                            <th className="sort">Restant</th>
                            <th className="sort">N° commande</th>
                            <th className="sort">Destiner à</th>
                            <th className="sort">Statut</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {renderTableData()}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <InvoiceAddModal
                    commandes={commandes}
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                  />
                  <CompanyInvoice
                    // commandes={commandes}
                    showInvoiceCompany={showInvoiceCompany}
                    entreprises={entreprises}
                    handleClose={handleClose}
                    handleToast={handleToast}
                  />
                  <InvoicePayment
                    selectedInvoice={selectedInvoice}
                    showStatus={showStatus}
                    handleClose={handleClose}
                    handleToast={handleToast}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Facture;

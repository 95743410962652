export const formaterNombreAvecEspace = (nombre) => {
  // Convertir le nombre en chaîne de caractères
  if (!nombre) {
    return "0";
  }
  let nombreString = nombre.toString();

  // Séparer la partie entière de la partie décimale (s'il y en a)
  let partieEntiere = nombreString;
  let partieDecimale = "";

  if (nombreString.includes(".")) {
    [partieEntiere, partieDecimale] = nombreString.split(".");
  }

  // Ajouter un espace tous les trois chiffres dans la partie entière
  let resultat = partieEntiere.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  // Si la partie décimale existe, l'ajouter au résultat avec un point
  if (partieDecimale !== "") {
    resultat += "." + partieDecimale;
  }

  return resultat ? resultat : "0";
};

export const validatePhoneNumber = (phoneNumber) => {
  const phoneRegex = /^\+\d{1,3}\d{7,12}$/;
  console.log(phoneRegex.test(phoneNumber));

  return phoneRegex.test(phoneNumber);

};
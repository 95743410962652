import ApiHelper from "../../../libs/Api";

export const commandeList = async (
  commandeListUrl = "/invoices/orders/all"
) => {
  try {
    return await ApiHelper.request(commandeListUrl);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const commandeCreationUrl = "/invoices/orders/create";

export const createCommande = async (body) => {
  try {
    return await ApiHelper.request(commandeCreationUrl, "post", body);
  } catch (error) {
    return error.response
  }
};

export const editCommande = async (commandeEditUrl, body) => {
  try {
    return await ApiHelper.request(commandeEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};

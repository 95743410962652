import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { createProduction } from "./services/production.service";
import DynamicFieldsWithSelect from "../formComponent/DynamicFieldsWithSelect";

const animatedComponents = makeAnimated();
function ProductionInitModal({
  produits,
  personnels,
  showAdd,
  handleClose,
  handleToast,
  showLoader,
  addProductionToList,
}) {
  const [options, setOptions] = useState({});
  const [selectedPersonnels, setSelectedPersonnels] = useState(null);
  const { register, handleSubmit } = useForm({
    shouldUnregister: true,
  });

  const productionCreation = async (data) => {
    showLoader(true);
    handleClose();
    const newData = Object.keys(data).reduce((result, key) => {
      // Vérifiez si la clé commence par "label" ou "value"
      if (key.startsWith("label")) {
        const index = key.replace("label", "");
        if (!result.product_in) {
          result.product_in = [];
        }
        if (!result.product_in[index - 1]) {
          result.product_in[index - 1] = {};
        }
        result.product_in[index - 1].quantity = parseInt(data[key]);
      } else if (key.startsWith("value")) {
        const index = key.replace("value", "");
        if (!result.product_in) {
          result.product_in = [];
        }
        if (!result.product_in[index - 1]) {
          result.product_in[index - 1] = {};
        }
        result.product_in[index - 1].code_product = data[key];
      } else {
        result[key] = data[key];
      }
      return result;
    }, {});

    if (newData) {
      let assignments = [];
      selectedPersonnels.forEach((element) => {
        assignments.push(element.value);
      });
      newData.assignments = assignments;
      newData.product_in = newData.product_in.filter(
        (element) =>
          element.code_product !== "" &&
          element.quantity !== NaN &&
          element.quantity > 0
      );
    }
    const response = await createProduction(newData);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      let data = response.data.data;
      data.products_in = newData.product_in;
      console.log(data.products_in);
      data.products_in.forEach((element) => {
        element.product = produits.find(
          (product) => product.code === element.code_product
        );
      });
      data.product_out = {
        // code_product: newData.product_out,
        product: produits.find(
          (product) => product.code === newData.product_out
        ),
      };
      console.log("New Object", data);
      state = true;
      message = response.data.message;
      type = "success";
      handleClose();
      handleToast(state, type, message);
      addProductionToList(data);
      // setTimeout(() => {
      //     window.location.reload(); // Recharge la page
      // }, 2000);
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };

  useEffect(() => {
    if (personnels) {
      const newOptions = personnels.map((item) => ({
        value: item.code,
        label: `${item.first_name} ${item.last_name}`,
      }));

      setOptions(() => newOptions);
    }
  }, [personnels]);
  return (
    <Modal
      show={showAdd}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Initialiser une production
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={handleSubmit((data) => productionCreation(data))}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label
                          className="form-label"
                          htmlFor="organizerMultiple"
                        >
                          Employé(s)
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="clarity:employee-group-solid"
                              className="text-white"
                              width="25"
                              height="25"
                            />
                          </span>
                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={options}
                            className="form-control"
                            onChange={(choice) => setSelectedPersonnels(choice)}
                            // {...register("assignments", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="first_step">
                          Etat de départ
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="vaadin:start-cog"
                              className="text-white"
                              width="25"
                              height="25"
                            />
                          </span>
                          <select
                            className="form-select js-choice"
                            id="first_step"
                            size="1"
                            name="first_step"
                            data-options='{"removeItemButton":true}'
                            {...register("first_step", { required: true })}
                          >
                            <option value="">Sélectionner un état...</option>
                            <option value="triage">Triage</option>
                            <option value="moulin">Moulin</option>
                            <option value="conditionnement">
                              Conditionnement
                            </option>
                            <option value="remplissage">Remplissage</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <fieldset class="border p-2 mb-3">
                      <legend class="float-none w-auto p-2">
                        Produit d'entrée
                      </legend>
                      <DynamicFieldsWithSelect
                        register={register}
                        produits={produits}
                      />
                    </fieldset>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="product_out">
                          Produit de sortie
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="gridicons:product-virtual"
                              className="text-white"
                              width="25"
                              height="25"
                            />
                          </span>
                          <select
                            className="form-select js-choice"
                            id="product_out"
                            size="1"
                            name="product_out"
                            data-options='{"removeItemButton":true}'
                            {...register("product_out", { required: true })}
                          >
                            <option value="">Sélectionner un produit...</option>
                            {produits && produits.length > 0
                              ? produits.map((produit) => (
                                  <option
                                    key={produit.code}
                                    value={produit.code}
                                  >
                                    {produit?.name} (
                                    {produit?.product_type.name})
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProductionInitModal;

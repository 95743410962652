import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

function DynamicFieldsForOrder({ register, produits, produit }) {
  const [fields, setFields] = useState([
    { id: 1, product: "", quantity: "", price: "" },
  ]);

  const addField = () => {
    const newField = {
      id: fields.length + 1,
      product: "",
      quantity: "",
      price: "",
    };
    setFields((prevFields) => [...prevFields, newField]);
  };

  const handleInputChange = (id, fieldKey, event) => {
    setFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, [fieldKey]: event.target.value } : field
      )
    );
  };

  const removeField = (id) => {
    setFields((prevFields) => prevFields.filter((field) => field.id !== id));
  };

  useEffect(() => {
    if (produit && produit.length > 0) {
      const initialFields = produit.map((item, index) => ({
        id: index + 1,
        product: item?.code || "", // Vérifiez que le code produit est défini.
        quantity: item?.quantity || "",
        price: item?.price || "",
      }));
      setFields(initialFields);
      console.log("Produit:", produit);
      console.log("Produits:", produits);
    }
  }, [produit]);

  return (
    <>
      {fields.map((field) => (
        <div className="row mb-3" key={field.id}>
          <div className="col-md-6">
            <div className="input-group mb-3">
              <span className="input-group-text bg-primary">
                <Icon
                  icon="gridicons:product-virtual"
                  width="25"
                  height="25"
                  className="text-white"
                />
              </span>
              <select
                className="form-select"
                value={field.product || ""} // Assurez-vous que field.product a une valeur par défaut.
                {...register(`product${field.id}`)}
                onChange={(e) => handleInputChange(field.id, "product", e)} // Gère le changement.
              >
                <option value="">Sélectionner un produit...</option>
                {produits &&
                  produits.map((produit) => (
                    <option key={produit.code} value={produit.code}>
                      {produit.name} ({produit.product_type.name})
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-group mb-3">
              <span className="input-group-text bg-primary">
                <Icon
                  icon="material-symbols:production-quantity-limits"
                  width="25"
                  height="25"
                  className="text-white"
                />
              </span>
              <input
                type="number"
                className="form-control sb-input"
                value={field.quantity}
                placeholder="La quantité"
                {...register(`quantity${field.id}`)}
                onChange={(e) => handleInputChange(field.id, "quantity", e)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-group mb-3">
              <span className="input-group-text bg-primary">
                <Icon
                  icon="tdesign:money"
                  width="25"
                  height="25"
                  className="text-white"
                />
              </span>
              <input
                type="number"
                className="form-control sb-input"
                value={field.price}
                placeholder="Le prix unitaire"
                {...register(`price${field.id}`)}
                onChange={(e) => handleInputChange(field.id, "price", e)}
              />
            </div>
          </div>
          <div className="col-md-2">
            <button
              onClick={() => removeField(field.id)}
              className="btn btn-outline-danger me-1 mb-1"
              type="button"
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
          <hr />
        </div>
      ))}

      <button
        onClick={addField}
        className="btn btn-outline-primary me-1 mb-3"
        type="button"
      >
        <i className="fas fa-plus"></i>
      </button>
    </>
  );
}

export default DynamicFieldsForOrder;

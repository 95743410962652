import React from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { dateFormatForSubmit } from "../../helpers/dates.helper";
import { createConge } from "./services/conge.service";

function CongeAddModal({
  personnels,
  showAdd,
  handleClose,
  handleToast,
  addCongeToList,
  showLoader,
}) {
  const { register, handleSubmit } = useForm();

  const congeCreation = async (data) => {
    showLoader(true);
    data.amount = parseInt(data.amount);
    data.date = dateFormatForSubmit(data.date);

    const response = await createConge(data);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      const conge = response.data.data;
      conge.personnel = personnels.find(
        (personnel) => personnel.code === data.code_personnel
      );
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      addCongeToList(conge);
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };

  return (
    <Modal
      show={showAdd}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Enrégistrer un congé
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={handleSubmit((data) => congeCreation(data))}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="date_debut">Date de debut:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-calendar text-white"></i>
                          </span>
                          <input
                            id="date_debut"
                            className="form-control sb-input"
                            type="date"
                            aria-label="Date de debut"
                            placeholder="Date de debut"
                            aria-describedby="basic-addon1"
                            {...register("date_departure", { required: false })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="date_fin">Date de fin:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-calendar text-white"></i>
                          </span>
                          <input
                            id="date_fin"
                            className="form-control sb-input"
                            type="date"
                            aria-label="Date de fin"
                            placeholder="Date de fin"
                            aria-describedby="basic-addon1"
                            {...register("date_recovery", { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="personnel">
                          Employé(s)
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="clarity:employee-group-solid"
                              className="text-white"
                              width="25"
                              height="25"
                            />
                          </span>
                          <select
                            className="form-select js-choice"
                            id="personnel"
                            size="1"
                            name="personnel"
                            data-options='{"removeItemButton":true}'
                            {...register("code_personnel", { required: true })}
                          >
                            <option value="">Sélectionner un employé...</option>
                            {personnels &&
                              personnels.map((personnel) => (
                                <option
                                  key={personnel.code}
                                  value={personnel.code}
                                >
                                  {personnel?.first_name} {personnel?.last_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CongeAddModal;

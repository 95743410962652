import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

export const renderActionButtons = (
  commande,
  handleDetailClick,
  handleEditClick
) => (
  <div className="d-flex align-items-center">
    <button
      className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal dropdown-caret-none"
      id="email-options"
      type="button"
      data-bs-toggle="dropdown"
      data-boundary="viewport"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span className="fas fa-ellipsis-v" data-fa-transform="down-2"></span>
    </button>
    <div
      className="dropdown-menu dropdown-menu-end border py-2"
      aria-labelledby="email-options"
    >
      <button
        className="dropdown-item"
        type="button"
        title="Détails"
        onClick={() => handleDetailClick(commande)}
      >
        <Icon className="me-2" icon="mdi:eye" width="20" height="20" />
        Voir plus
      </button>
      <div className="dropdown-divider"></div>
      <Link className="dropdown-item" to={`/me/commande/${commande?.code}`}>
        <Icon
          className="me-2"
          icon="fa-solid:file-invoice"
          width="15"
          height="20"
        />
        <span>voir le proforma</span>
      </Link>

      <div className="dropdown-divider"></div>
      <button
        className="dropdown-item"
        type="button"
        title="Modifier"
        onClick={() => handleEditClick(commande)}
      >
        <Icon icon="ix:pen-filled" width="20" height="20" className="me-2" />
        Modifier la commande
      </button>
    </div>
  </div>
);

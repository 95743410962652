import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BGHOLDER as bg } from "../../constants";
import { Icon } from "@iconify/react";

import Loader from "../../components/partials/Loader";
import PersonnelAddModal from "../../components/personnels/PersonnelAddModal";
import PersonnelUpdateModal from "../../components/personnels/PersonnelUpdateModal";
import {
  personnelList,
  personnelTypeList,
} from "../../components/personnels/services/personnel.service";
import PersonnelDetailModal from "../../components/personnels/PersonnelDetailModal";
import ToastMessage from "../../components/messages/ToastMessage";
import Pagination from "../../components/partials/Pagination";
import { useTrail, animated as a } from "react-spring";

function Personnels(props) {
  const [personnels, setPersonnels] = useState([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState(null);
  const [personnelTypes, setPersonnelTypes] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [toggle, setToggle] = useState(true);
  const trail = useTrail(personnels?.length || 0, {
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
  });
  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowDetail(false);
    setShowAdd(false);
    setShowEdit(false);
  };

  const handleShowDetail = useCallback(() => setShowDetail(true), []);
  const handleShowAdd = useCallback(() => setShowAdd(true), []);
  const handleShowEdit = () => setShowEdit(true);
  const showLoader = useCallback((state) => setLoaderState(state), []);
  const handleDetailClick = (Personnel) => {
    setSelectedPersonnel(Personnel);
    handleShowDetail();
  };

  const handleEditClick = (Personnel) => {
    setSelectedPersonnel(Personnel);
    // Affichez le modal pour l'édition ici
    handleShowEdit();
  };

  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await personnelList(
        `/personnals/all?page=${page}&limit=10`
      );
      setPersonnels(response.data.data.list);
      setPaginator(response.data.data.pagination);

      setLoaderState(false);
    } catch (error) {}
  };
  const fetchPersonnelList = useCallback(async () => {
    try {
      const { data } = await personnelList("/personnals/all?limit=10");
      setPersonnels(data.data.list);
      setPaginator(data.data.pagination);
    } catch {
    } finally {
      setLoaderState(false);
    }
  }, []);

  const fetchPersonnelType = async () => {
    try {
      const response = await personnelTypeList();
      setPersonnelTypes(response.data.data.list);
      setLoaderState(false);
    } catch (error) {}
  };

  const addPersonnelToList = useCallback((newPersonnel) => {
    setPersonnels((prevPersonnels) => [newPersonnel, ...prevPersonnels]);
  }, []);

  /**
   * Updates a Personnel in the list.
   *
   * @param {Object} updatedPersonnel - The updated Personnel object.
   * @return {void}
   */
  const updatePersonnelInList = useCallback((updatedPersonnel) => {
    setPersonnels((prevPersonnels) =>
      prevPersonnels.map((personnel) =>
        personnel.code === updatedPersonnel.code ? updatedPersonnel : personnel
      )
    );
  }, []);

  const renderTableData = useMemo(() => {
    if (!personnels || personnels.length === 0) {
      return (
        <tr>
          <td colSpan="5" className="text-center">
            Aucun Personnel disponible.
          </td>
        </tr>
      );
    }

    return trail.map(({ x, ...rest }, index) => (
      <a.tr
        key={personnels[index].code}
        style={{ ...rest, transform: x.to((x) => `translate3d(0,${x}px,0)`) }}
      >
        <td className="text-truncate">{personnels[index]?.ref}</td>
        <td className="text-truncate">{personnels[index]?.first_name}</td>
        <td className="text-truncate">{personnels[index]?.last_name}</td>
        <td className="text-truncate">{personnels[index]?.phone}</td>
        <td className="text-truncate">{personnels[index]?.category?.name}</td>
        <td>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary px-2 py-1 detail"
              type="button"
              title="voir détail"
              onClick={() => handleDetailClick(personnels[index])}
            >
              <i className="text-500 text-white far fa-eye"></i>
            </button>
            <button
              className="btn ms-2 btn-warning px-2 py-1"
              onClick={() => handleEditClick(personnels[index])}
              type="button"
              title="Modifier"
            >
              <i className="text-500 text-white fas fa-edit"></i>
            </button>
          </div>
        </td>
      </a.tr>
    ));
  }, [personnels, trail]);

  useEffect(() => {
    setLoaderState(true);
    fetchPersonnelList();
    fetchPersonnelType();
  }, []);

  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="clarity:employee-group-solid" width="50" />{" "}
                    Personnels
                  </h3>
                  <p className="mb-0">Gestion du personnel</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">nouveau employé</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="text"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                    <span className="input-group-text" id="basic-addon3">
                      <a href="" className="search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-12 input-group" id="search_box">
                    <select
                      className="form-select js-choice mb-3"
                      id="personnel_role"
                      size="1"
                      name="personnel_role"
                      data-options='{"removeItemButton":true}'
                    >
                      <option value="">Sélectionner un rôle...</option>
                      <option value="">Gestionnaire de Stock</option>
                      <option value="">Comptable</option>
                      <option value="">Remplisseur</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header border-bottom">
                <div className="col-auto align-self-center">
                  <h5 className="mb-2" data-anchor="data-anchor">
                    Liste du Personnel
                  </h5>

                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    {/* <div className="col-12 mb-2">
                                            <input type="text" className="form-control input-sm" placeholder="Rechercher" id="searchInput" />
                                        </div> */}
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort" data-sort="id">
                              ID
                            </th>
                            <th className="sort" data-sort="name">
                              Prenom
                            </th>
                            <th className="sort" data-sort="name">
                              Nom
                            </th>
                            <th className="sort" data-sort="telephone">
                              Telephone
                            </th>
                            <th className="sort" data-sort="role">
                              Role
                            </th>
                            <th className="sort" data-sort="age">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list_admin">{renderTableData}</tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PersonnelAddModal
          personnelTypes={personnelTypes}
          showAdd={showAdd}
          handleClose={handleClose}
          handleToast={handleToast}
          addPersonnelToList={addPersonnelToList}
          showLoader={showLoader}
        />
        <PersonnelUpdateModal
          selectedPersonnel={selectedPersonnel}
          personnelTypes={personnelTypes}
          showEdit={showEdit}
          handleClose={handleClose}
          handleToast={handleToast}
          updatePersonnelInList={updatePersonnelInList}
          showLoader={showLoader}
        />
        <PersonnelDetailModal
          selectedPersonnel={selectedPersonnel}
          showDetail={showDetail}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
}

export default Personnels;

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { BGHOLDER as bg, checkStatusCodes } from "../../../constants";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import { createInvoiceCompany } from "../services/invoice.service";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { formatDateForInput } from "../../../helpers/dates.helper";

const animatedComponents = makeAnimated();

function CompanyInvoice({
  entreprises,
  showInvoiceCompany,
  handleClose,
  handleToast,
  showLoader,
}) {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [selectedEntreprise, setSelectedEntreprise] = useState([]);
  const [title, setTitle] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setdateEnd] = useState("");
  // Utilisez useEffect pour mettre à jour les options seulement quand `entreprises` change.
  useEffect(() => {
    if (entreprises && entreprises.length > 0) {
      const newOptions = entreprises.map((item) => ({
        value: item.code,
        label: `${item.name}`,
      }));
      setOptions(newOptions);
    }
  }, [entreprises]); // Ne se déclenche que si `entreprises` change.

  const invoiceCampanyCreation = async (e) => {
    // handleClose();
    e.preventDefault();
    let codeCompanies = [];
    selectedEntreprise.forEach((element) => {
      codeCompanies.push(element.value);
    });

    const data = {
      code_companies: codeCompanies,
      title: title,
      date_start: formatDateForInput(dateStart),
      date_end: formatDateForInput(dateEnd),
    };
    const date_start = formatDateForInput(data.date_start);
    const date_end = formatDateForInput(data.date_end);
    const response = await createInvoiceCompany(
      "/invoices/company/create",
      data
    );
    let state = false;
    let message = "";
    let type = "";

    if (checkStatusCodes(response.data.statusCode)) {
      state = true;
      message = response.data.message;
      const myData = response.data.data;
      const data = {
        date_start: date_start,
        date_end: date_end,
        myData: myData,
      };
      type = "success";
      handleToast(state, type, message);
      setTimeout(() => {
        navigate("/me/facture/Company", { state: data });
      }, 2000);
    } else {
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
    console.log(response.data);
  };

  return (
    <Modal
      show={showInvoiceCompany}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Enrégistrer une facture d'entreprise
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={(e) => invoiceCampanyCreation(e)}>
              <div className="col-lg-12">
                <div className="d-flex mb-3">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="name">Le Titre:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="mdi:rename"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="name"
                            className="form-control sb-input"
                            type="text"
                            aria-label="Le titre"
                            placeholder="Le titre"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="produitType">
                          Liste commande
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              className="text-white"
                              icon="mdi:company"
                              width="25"
                              height="25"
                            />
                          </span>
                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={options ?? []}
                            className="form-control"
                            onChange={(choice) => setSelectedEntreprise(choice)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="date_debut">Date de debut:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-calendar text-white"></i>
                          </span>
                          <input
                            id="date_debut"
                            className="form-control sb-input"
                            type="datetime-local"
                            aria-label="Date de debut"
                            placeholder="Date de debut"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setDateStart(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="date_fin">Date de fin:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-calendar text-white"></i>
                          </span>
                          <input
                            id="date_fin"
                            className="form-control sb-input"
                            type="datetime-local"
                            aria-label="Date de fin"
                            placeholder="Date de fin"
                            aria-describedby="basic-addon1"
                            onChange={(e) => setdateEnd(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CompanyInvoice;

import { LogOut } from "../helpers/storage.helper";

const STATUS_CODES_SUCCESS = [200, 201];
const STATUS_CODES_ERROR = [400, 401, 402, 404, 403, 500, 501];

export const checkStatusCodes = (status) => {
    const parsedStatus = parseInt(status);

    if (STATUS_CODES_SUCCESS.includes(parsedStatus)) {
        return true;
    }

    if (parsedStatus === 403) {
        LogOut(); // Call the logout function
        return false;
    }

    if (STATUS_CODES_ERROR.includes(parsedStatus)) {
        return false;
    }
    return false;
};


export const BGHOLDER = {
    backgroundImage: "url(../assets/img/icons/spot-illustrations/corner-2.png)"
}
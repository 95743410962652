import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/partials/Loader";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Icon } from "@iconify/react";
import ToastMessage from "../../components/messages/ToastMessage";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { formaterNombreAvecEspace } from "../../helpers/numbers.helper";
import { dateFormatToFr } from "../../helpers/dates.helper";
import { commandeList } from "./services/commande.service";

const invoiceStyles = {
  body: {
    background: "#ccc",
    padding: "0px",
  },
  container: {
    width: "21cm",
    minHeight: "29.7cm",
  },
  invoice: {
    background: "#fff",
    width: "100%",
    padding: "50px",
  },
  logo: {
    width: "3.0cm",
    height: "3.0cm",
  },
  documentType: {
    textAlign: "right",
    color: "#444",
  },
  conditions: {
    fontSize: "0.7em",
    color: "#666",
  },
  bottomPage: {
    fontSize: "0.7em",
  },
};

function Proforma(props) {
  const { id } = useParams();
  const [facture, setFacture] = useState({});
  const componentRef = useRef();

  const [loaderState, setLoaderState] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });

  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const fetchFature = async () => {
    const response = await commandeList(`/invoices/orders/${id}`);
    if (checkStatusCodes(response.data.statusCode)) {
      setFacture(response.data.data);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  useEffect(() => {
    fetchFature();
    console.log("facture", facture);
  }, []);
  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="basil:invoice-solid" width="50" height="50" />{" "}
                    Commande N°{" "}
                    <span className="text-success">{facture?.number}</span>
                  </h3>
                  <Link
                    class="btn btn-link btn-sm ps-0 mt-2"
                    to={"/me/commande"}
                  >
                    <span
                      class="fas fa-list"
                      data-fa-transform="shrink-3 down-2"
                    ></span>
                    La liste des commandes
                    <span class="fas fa-chevron-right ms-1 fs--2"></span>
                  </Link>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-md-6 p-3"></div>
                    <div className="col-md-6 p-3">
                      <div
                        className="d-flex align-items-center justify-content-start my-3"
                        id="bulk-select-replace-element"
                      >
                        <button
                          className="btn btn-falcon-success btn-sm"
                          type="button"
                          title="Imprimer la facture"
                          onClick={handlePrint}
                        >
                          <Icon icon="flat-color-icons:print" width="25" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <FactureBody
                    data={facture}
                    invoiceStyles={invoiceStyles}
                    ref={componentRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Proforma;

const FactureBody = React.forwardRef(
  ({ data, invoiceStyles }, componentRef) => {
    console.log("data", data);

    return (
      <div className="invoice" ref={componentRef} style={invoiceStyles.invoice}>
        <div className="row">
          <div className="col-7">
            <img
              src="../../../assets/logo_anita.png"
              className="logo rounded-circle"
              alt="Logo"
              style={invoiceStyles.logo}
            />
          </div>
          <div className="col-5">
            <h3
              className="document-type fw-bold"
              style={invoiceStyles.documentType}
            >
              PROFORMA
            </h3>
            <p className="float-end text-right fs-1">
              <strong>N° {data?.number}</strong>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-7">
            <p>
              <strong>ANITA INDUSTRIE SUARL</strong>
              <br />
              NINEA : 007716171 2T2
              <br />
              RC : 2020 B 416
              <br />
              Tél : 77 649 40 55
              <br />
              {/* N° BON DE COMMANDE: {data?.order?.number} */}
            </p>
          </div>
          <div className="col-5">
            <br />

            <p className="float-end fs--4">
              Client: <strong>{data?.owner?.name}</strong>
              <br />
              Date:{" "}
              <strong>
                {data?.date ? dateFormatToFr(data?.date).split(" ")[0] : ""}
              </strong>
            </p>
          </div>
        </div>
        <br />

        <table className="table table-striped">
          <thead>
            <tr>
              <th>Produit(s)</th>
              <th>Quantité</th>
              <th>Prix Unitaire</th>
              <th>Prix Total</th>
              {/* <th>PU HT</th>
                <th>TVA</th>
                <th>Total HT</th> */}
            </tr>
          </thead>
          <tbody>
            {data && data?.products?.length > 0 ? (
              data?.products.map((Produit) => (
                <tr key={Produit?.product?.code}>
                  <td>{Produit?.product?.name}</td>
                  <td>{Produit?.quantity}</td>
                  <td>{formaterNombreAvecEspace(Produit?.price)} XOF</td>
                  <td>{formaterNombreAvecEspace(Produit?.total_price)} XOF</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  Aucun élement disponible.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="row">
          <div className="col-8"></div>
          <div className="col-4 mt-3">
            <table className="table table-sm ">
              <tr>
                <td>
                  <strong>Total HT</strong>
                </td>
                <td>{formaterNombreAvecEspace(data?.amount_ht)} XOF</td>
              </tr>
              <tr>
                <td>
                  <strong>TVA {formaterNombreAvecEspace(data?.tva)}%</strong>{" "}
                </td>
                <td>{formaterNombreAvecEspace(data?.amount_tva)} XOF</td>
              </tr>
              <tr>
                <td>
                  <strong>Total TTC</strong>
                </td>
                <td>{formaterNombreAvecEspace(data?.amount_ttc)} XOF</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  }
);

import ApiHelper from "../../../../libs/Api";

export const produitList = async (
  produitListUrl = "/products/all?page=1&limit=10"
) => {
  try {
    return await ApiHelper.request(produitListUrl);
  } catch (error) {
    return error.response;
  }
};

export const produitTypes = async (produitTypeUrl = "/products/types/all") => {
  try {
    return await ApiHelper.request(produitTypeUrl);
  } catch (error) {
    return error.response;
  }
};

const produitCreationUrl = "/products/create";

export const createProduit = async (body) => {
  try {
    return await ApiHelper.request(produitCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editProduit = async (produitEditUrl, body) => {
  try {
    return await ApiHelper.request(produitEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};

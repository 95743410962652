import React, { useEffect, useState, useMemo } from "react";
import { useTrail, animated as a } from "react-spring";
import Loader from "../../components/partials/Loader";
import { Icon } from "@iconify/react";

import ToastMessage from "../../components/messages/ToastMessage";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import Pagination from "../../components/partials/Pagination";
import { historiqueStockList } from "../../components/stock/historique/services/historisque.service";
import HistorisqueDetailModal from "../../components/stock/historique/HistorisqueDetailModal";
import { dateFormatToFr } from "../../helpers/dates.helper";

function HistoriqueStock(props) {
  const [historiques, setHistoriques] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedHistorique, setSelectedHistorique] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });

  const trail = useTrail(historiques?.length || 0, {
    from: { opacity: 0, transform: "translate3d(0,40px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    config: { mass: 1, tension: 200, friction: 15 },
  });

  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowDetail(false);
  };

  const handleShowDetail = () => setShowDetail(true);

  const fetchEntrpriseList = async () => {
    const response = await historiqueStockList();
    if (checkStatusCodes(response.data.statusCode)) {
      setHistoriques(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  const handleDetailClick = (historique) => {
    setSelectedHistorique(historique);
    handleShowDetail();
  };

  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await historiqueStockList(
        `/stocks/histories/all?page=${page}&limit=10`
      );
      setHistoriques(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } catch (error) {}
  };

  const renderTableData = useMemo(() => {
    return (
      <tbody className="list_admin">
        {historiques && historiques.length > 0 ? (
          trail.map((style, index) => (
            <a.tr key={historiques[index]?.code} style={style}>
              <a.td>{historiques[index]?.product?.name}</a.td>
              <a.td>
                {historiques[index]?.action === "ENTREE" ? (
                  <span className="badge rounded-pill badge-soft-primary">
                    {historiques[index]?.action}
                  </span>
                ) : historiques[index]?.action === "SORTIE" ? (
                  <span className="badge rounded-pill badge-soft-warning">
                    {historiques[index]?.action}
                  </span>
                ) : (
                  <span className="badge rounded-pill badge-soft-danger">
                    N/A
                  </span>
                )}
              </a.td>
              <a.td>
                {historiques[index]?.quantity}{" "}
                {historiques[index]?.product?.unit_of_measure}
              </a.td>
              <a.td>{historiques[index]?.product?.product_type?.name}</a.td>
              <a.td>{dateFormatToFr(historiques[index]?.date)}</a.td>
              <a.td>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn ms-2 btn-primary px-2 py-1"
                    type="button"
                    title="Modifier"
                    onClick={() => handleDetailClick(historiques[index])}
                  >
                    <i className="text-500 text-white fas fa-eye"></i>
                  </button>
                </div>
              </a.td>
            </a.tr>
          ))
        ) : (
          <tr>
            <td colSpan="5" className="text-center">
              Aucun historique disponible.
            </td>
          </tr>
        )}
      </tbody>
    );
  }, [historiques, trail]);

  useEffect(() => {
    setLoaderState(true);
    fetchEntrpriseList();
    // fetchUserType()
  }, []);
  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="clarity:timeline-line" width="50" height="50" />{" "}
                    Historique
                  </h3>
                  <p className="mb-0">Historique du stock</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-md-4">
                  <div className="col-12 input-group">
                    <input
                      type="date"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="col-12 input-group">
                    <select
                      className="form-select js-choice mb-3"
                      id="personnel_role"
                      size="1"
                      name="personnel_role"
                      data-options='{"removeItemButton":true}'
                    >
                      <option value="">Sélectionner un produit...</option>
                      <option value="">Pument</option>
                      <option value="">Poivre</option>
                      <option value="">curcument</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="col-12 input-group">
                    <select
                      className="form-select js-choice mb-3"
                      id="personnel_role"
                      size="1"
                      name="personnel_role"
                      data-options='{"removeItemButton":true}'
                    >
                      <option value="">Sélectionner une action...</option>
                      <option value="">Entré</option>
                      <option value="">Sortie</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Les historiques du stock
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort" data-sort="name">
                              Nom produit
                            </th>
                            <th className="sort" data-sort="email">
                              Mouvement
                            </th>
                            <th className="sort" data-sort="telephone">
                              Quantité
                            </th>
                            <th className="sort" data-sort="statut">
                              Type de produit
                            </th>
                            <th className="sort" data-sort="statut">
                              Date
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {renderTableData}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <HistorisqueDetailModal
                    selectedHistorique={selectedHistorique}
                    showDetail={showDetail}
                    handleClose={handleClose}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoriqueStock;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTrail, animated as a } from "react-spring";
import { Icon } from "@iconify/react";

import Loader from "../../components/partials/Loader";
import ToastMessage from "../../components/messages/ToastMessage";
import { personnelList } from "../../components/personnels/services/personnel.service";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import Pagination from "../../components/partials/Pagination";
import { productionList } from "../../components/production/services/production.service";
import { dateFormatToFr } from "../../helpers/dates.helper";
import ProductionInitModal from "../../components/production/ProductionInitModal";
import { produitList } from "../../components/stock/produit/services/produit.service";
import { Link } from "react-router-dom";
import ProductionDetailModal from "../../components/production/ProductionDetailModal";
import ProductionEditModal from "../../components/production/ProductionEditModal";

function Production(props) {
  const [productions, setProductions] = useState([]);
  const [personnels, setPersonnels] = useState([]);
  const [produits, setProduits] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedProduction, setSelectedProduction] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });

  const trail = useTrail(productions?.length || 0, {
    from: { opacity: 0, transform: "translate3d(0,40px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    config: { mass: 1, tension: 200, friction: 15 },
  });

  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowAdd(false);
    setShowDetail(false);
    setShowEdit(false);
  };
  const handleShowAdd = useCallback(() => setShowAdd(true), []);
  const handleShowDetail = useCallback(() => setShowDetail(true), []);
  const handleShowEdit = () => setShowEdit(true);
  const showLoader = useCallback((state) => setLoaderState(state), []);

  const handleEditClick = useCallback((Production) => {
    setSelectedProduction(Production);
    // Affichez le modal pour l'édition ici
    handleShowEdit();
  }, []);

  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await productionList(
        `/productions/all?page=${page}&limit=10`
      );
      setProductions(response.data.data.list);
      setPaginator(response.data.data.pagination);

      setLoaderState(false);
    } catch (error) {}
  };

  const fetchProductionList = async () => {
    const response = await productionList();
    if (checkStatusCodes(response.data.statusCode)) {
      setProductions(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  const fetchPersonnelList = async () => {
    try {
      const response = await personnelList();
      setPersonnels(response.data.data.list);
      setLoaderState(false);
    } catch (error) {}
  };

  const fetchProduitList = async () => {
    try {
      const response = await produitList();
      setProduits(response.data.data.list);
      setLoaderState(false);
    } catch (error) {}
  };
  // const type = usersType
  const handleDetailClick = (production) => {
    // Mettez à jour l'état avec les données de l'utilisateur sélectionné
    // setProductionsTypes(type)
    setSelectedProduction(production);
    // Affichez le modal pour l'édition ici
    handleShowDetail();
  };

  const addProductionToList = useCallback((newProduction) => {
    setProductions((prevProductions) => [newProduction, ...prevProductions]);
  }, []);

  const updateProductionInList = useCallback((updatedProduction) => {
    setProductions((prevProductions) =>
      prevProductions.map((production) =>
        production.code === updatedProduction.code
          ? updatedProduction
          : production
      )
    );
  });
  const renderTableData = useMemo(() => {
    if (!productions || productions.length === 0) {
      return (
        <tr>
          <td colSpan="7" className="text-center">
            Aucun Production disponible.
          </td>
        </tr>
      );
    }

    return trail.map(({ opacity, transform }, index) => (
      <a.tr
        key={productions[index]?.code}
        style={{
          opacity,
          transform,
        }}
      >
        <a.td>
          <span className="badge rounded-pill badge-soft-secondary">
            {productions[index]?.code}
          </span>
        </a.td>
        <a.td>
          {productions[index]?.products_in &&
          productions[index]?.products_in.length > 0
            ? productions[index]?.products_in.map((product_in, idx) => (
                <span
                  key={idx}
                  className="badge rounded-pill badge-soft-primary ms-2"
                >
                  {product_in?.product.name}
                </span>
              ))
            : null}
        </a.td>
        <a.td>
          <span className="badge rounded-pill badge-soft-success">
            {productions[index]?.product_out?.product?.name}
          </span>
        </a.td>
        <a.td>{dateFormatToFr(productions[index]?.date_start)}</a.td>
        <a.td>{dateFormatToFr(productions[index]?.date_end) ?? "N/A"}</a.td>
        <a.td>
          {productions[index]?.status === "in_progress" ? (
            <span className="badge rounded-pill badge-soft-secondary">
              En cour
            </span>
          ) : productions[index]?.status === "canceled" ? (
            <span className="badge rounded-pill badge-soft-danger">
              Terminer
            </span>
          ) : (
            <span className="badge rounded-pill badge-soft-success">
              Terminer
            </span>
          )}
        </a.td>
        <a.td>
          <div className="d-flex">
            {productions[index]?.status === "in_progress" ? (
              <Link
                className="btn btn-falcon-warning btn-sm"
                title="suivre la production"
                to={`/me/production/${productions[index]?.code}`}
              >
                Etape suivante
              </Link>
            ) : (
              <button
                className="btn btn-falcon-warning btn-sm disabled"
                type="button"
                title="Modifier"
              >
                Etape suivante
              </button>
            )}
            <button
              className="btn ms-2 btn-primary px-2 py-1"
              type="button"
              title="voir detail"
              onClick={() => handleDetailClick(productions[index])}
            >
              <i className="text-500 text-white fas fa-eye"></i>
            </button>
            <button
              style={{ display: "none" }}
              className="btn ms-2 btn-warning px-2 py-1"
              onClick={() => handleEditClick(productions[index])}
              type="button"
              title="Modifier"
            >
              <i className="text-500 text-white fas fa-edit"></i>
            </button>
          </div>
        </a.td>
      </a.tr>
    ));
  }, [productions, trail]);

  useEffect(() => {
    setLoaderState(true);
    fetchProductionList();
    fetchPersonnelList();
    fetchProduitList();
  }, []);

  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon
                      icon="fluent:production-checkmark-20-filled"
                      width="50"
                      height="50"
                    />{" "}
                    Productions
                  </h3>
                  <p className="mb-0">Gestion de la production</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">Initialiser une production</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-12 input-group">
                    <input
                      type="date"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Production
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort" data-sort="email">
                              Identifiant
                            </th>
                            <th className="sort" data-sort="email">
                              Produit d'entrée
                            </th>
                            <th className="sort" data-sort="name">
                              Produit de sortie
                            </th>
                            {/* <th className="sort" data-sort="name">Etape production </th> */}
                            <th className="sort" data-sort="name">
                              Date de debut
                            </th>
                            <th className="sort" data-sort="email">
                              Date de fin
                            </th>
                            <th className="sort" data-sort="email">
                              Statut
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody className="list_admin">{renderTableData}</tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <ProductionInitModal
                    produits={produits}
                    personnels={personnels}
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    showLoader={showLoader}
                    addProductionToList={addProductionToList}
                  />
                  <ProductionDetailModal
                    selectedProduction={selectedProduction}
                    showDetail={showDetail}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    showLoader={showLoader}
                  />
                  {selectedProduction && (
                    <ProductionEditModal
                      produits={produits}
                      personnels={personnels}
                      selectedProduction={selectedProduction}
                      showEdit={showEdit}
                      showLoader={showLoader}
                      handleClose={handleClose}
                      handleToast={handleToast}
                      updateProductionInList={updateProductionInList}
                    />
                  )}
                  {/* <UserStatusUpdateModal /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Production;

import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Icon } from "@iconify/react";
import { BGHOLDER as bg } from "../../constants";
import { dateFormatToFr } from "../../helpers/dates.helper";
import { formaterNombreAvecEspace } from "../../helpers/numbers.helper";

const invoiceStyles = {
  body: {
    background: "#ccc",
    padding: "0px",
  },
  container: {
    width: "21cm",
    minHeight: "29.7cm",
  },
  invoice: {
    background: "#fff",
    width: "100%",
    padding: "50px",
  },
  logo: {
    width: "3.0cm",
    height: "3.0cm",
  },
  documentType: {
    textAlign: "right",
    color: "#444",
  },
  conditions: {
    fontSize: "0.7em",
    color: "#666",
  },
  bottomPage: {
    fontSize: "0.7em",
  },
};

function CompanyInvoicePage(props) {
  const location = useLocation();
  const { date_start, date_end, myData } = location.state;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {}, []);
  return (
    <div>
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="basil:invoice-solid" width="50" height="50" />{" "}
                    Factures entreprise
                  </h3>
                  <Link
                    class="btn btn-link btn-sm ps-0 mt-2"
                    to={"/me/facture"}
                  >
                    <span
                      class="fas fa-list"
                      myData-fa-transform="shrink-3 down-2"
                    ></span>
                    La liste des facture
                    <span class="fas fa-chevron-right ms-1 fs--2"></span>
                  </Link>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-md-6 p-3"></div>
                    <div className="col-md-6 p-3">
                      <div
                        className="d-flex align-items-center justify-content-start my-3"
                        id="bulk-select-replace-element"
                      >
                        <button
                          className="btn btn-falcon-success btn-sm"
                          type="button"
                          title="Imprimer la facture"
                          onClick={handlePrint}
                        >
                          <Icon icon="flat-color-icons:print" width="25" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <FactureBody
                    myData={myData}
                    date_start={dateFormatToFr(date_start).split(" ")[0]}
                    date_end={dateFormatToFr(date_end).split(" ")[0]}
                    invoiceStyles={invoiceStyles}
                    ref={componentRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyInvoicePage;

const FactureBody = React.forwardRef(
  ({ date_start, date_end, myData, invoiceStyles }, componentRef) => {
    return (
      <div className="invoice" ref={componentRef} style={invoiceStyles.invoice}>
        <div className="row">
          <div className="col-7">
            <img
              src="../../../assets/logo_anita.png"
              className="logo rounded-circle"
              alt="Logo"
              style={invoiceStyles.logo}
            />
          </div>
          <div className="col-5"></div>
        </div>
        <div className="row">
          <div className="col-7">
            <p>
              <strong>ANITA INDUSTRIE SUARL</strong>
              <br />
              NINEA : 007716171 2T2
              <br />
              RC : 2020 B 416
              <br />
              Tél : 77 649 40 55
              <br />
            </p>
          </div>
        </div>
        <br />
        <h5
          className="document-type text-center"
          style={invoiceStyles.documentType}
        >
          Factures du {date_start} au {date_end}
        </h5>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>DATE</th>
              <th>N° BON COMMANDE</th>
              <th>SUPERMACHÉS</th>
              <th>N° FACTURE</th>
              <th>MONTANT</th>
              {/* <th>PU HT</th>
                <th>TVA</th>
                <th>Total HT</th> */}
            </tr>
          </thead>
          <tbody>
            {myData && myData?.length > 0 ? (
              myData?.map((item) => (
                <tr key={item?.date}>
                  <td>
                    {item?.date ? dateFormatToFr(item?.date).split(" ")[0] : ""}
                  </td>
                  <td>{item?.numero_commande}</td>
                  <td>{item?.comapny}</td>
                  <td>{item?.number_invoice}</td>
                  <td>{formaterNombreAvecEspace(item?.amount)} XOF</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  Aucun élement disponible.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
);
